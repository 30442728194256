import { IconTypeEnum } from "interfaces";
import {
  IoIosNotificationsOutline,
  IoIosClose,
  IoMdJournal,
} from "react-icons/io";
import {
  IoRadioButtonOff,
  IoRadioButtonOn,
  IoReloadCircleOutline,
} from "react-icons/io5";
import { GiHouseKeys } from "react-icons/gi";
import {
  AiOutlineCalendar,
  AiOutlineCheckCircle,
  AiOutlineCheck,
  AiOutlineSearch,
  AiOutlineUnorderedList,
  AiFillCaretUp,
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineMail,
  AiFillCheckCircle,
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiFillInfoCircle,
  AiFillCaretDown,
  AiFillCaretRight,
} from "react-icons/ai";
import {
  BsBuilding,
  BsImages,
  BsLayers,
  BsQuestionCircle,
  BsSquare,
  BsSquareFill,
  BsDot,
  BsCheckAll,
  BsCurrencyDollar,
  BsDownload,
  BsFileEarmarkText,
} from "react-icons/bs";
import {
  BiChevronLeft,
  BiChevronRight,
  BiTrash,
  BiStats,
  BiMinus,
  BiPlus,
} from "react-icons/bi";
import { MdOutlineTimer, MdToggleOff, MdToggleOn } from "react-icons/md";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { AiOutlineSetting } from "react-icons/ai";
import { FaRegHandshake } from "react-icons/fa";
import { VscTag } from "react-icons/vsc";
import { CiDiscount1 } from "react-icons/ci";

import { RiEditLine } from "react-icons/ri";
import { FiAlertTriangle } from "react-icons/fi";
import { GrAttachment } from "react-icons/gr";
import { ImFilePdf } from "react-icons/im";

const getIconHelper = (iconType: IconTypeEnum) => {
  switch (iconType) {
    case IconTypeEnum.LAYER:
      return BsLayers;
    case IconTypeEnum.RIGHT_BOLD:
      return AiFillCaretRight;
    case IconTypeEnum.FILE:
      return BsFileEarmarkText;
    case IconTypeEnum.ATTACHMENT:
      return GrAttachment;
    case IconTypeEnum.UN_ORDER_LIST:
      return AiOutlineUnorderedList;
    case IconTypeEnum.INFO:
      return AiFillInfoCircle;
    case IconTypeEnum.ALERT:
      return FiAlertTriangle;
    case IconTypeEnum.DOLLAR:
      return BsCurrencyDollar;
    case IconTypeEnum.MINUS:
      return BiMinus;
    case IconTypeEnum.EYE_OPEN:
      return AiOutlineEye;
    case IconTypeEnum.EYE_CLOSE:
      return AiOutlineEyeInvisible;
    case IconTypeEnum.PDF:
      return ImFilePdf;
    case IconTypeEnum.CHECK_FILL:
      return AiFillCheckCircle;
    case IconTypeEnum.UN_SELECTED_RADIO:
      return IoRadioButtonOff;
    case IconTypeEnum.SELECTED_RADIO:
      return IoRadioButtonOn;
    case IconTypeEnum.MAIL:
      return AiOutlineMail;
    case IconTypeEnum.CHECK_ALL:
      return BsCheckAll;
    case IconTypeEnum.DOWNLOAD:
      return BsDownload;
    case IconTypeEnum.DOT:
      return BsDot;
    case IconTypeEnum.DOWN_ARROW:
      return AiOutlineArrowDown;
    case IconTypeEnum.UP_ARROW:
      return AiOutlineArrowUp;
    case IconTypeEnum.RELOAD:
      return IoReloadCircleOutline;
    case IconTypeEnum.KEYS:
      return GiHouseKeys;

    case IconTypeEnum.STAT_TAGS:
      return VscTag;
    case IconTypeEnum.GRAPH:
      return BiStats;
    case IconTypeEnum.SETTLED:
      return FaRegHandshake;
    case IconTypeEnum.DISCOUNT:
      return CiDiscount1;
    case IconTypeEnum.JOURNAL:
      return IoMdJournal;
    case IconTypeEnum.DOWN:
      return AiFillCaretDown;
    case IconTypeEnum.UP:
      return AiFillCaretUp;
    case IconTypeEnum.SETTING:
      return AiOutlineSetting;
    case IconTypeEnum.CHECK:
      return AiOutlineCheck;
    case IconTypeEnum.Images:
      return BsImages;
    case IconTypeEnum.CLOSE:
      return IoIosClose;
    case IconTypeEnum.UN_CHECK:
      return AiOutlineCheckCircle;
    case IconTypeEnum.TOGGLE_OFF:
      return MdToggleOff;
    case IconTypeEnum.TOGGLE_ON:
      return MdToggleOn;
    case IconTypeEnum.LEFT:
      return BiChevronLeft;
    case IconTypeEnum.BUILDING:
      return BsBuilding;
    case IconTypeEnum.EDIT:
      return RiEditLine;
    case IconTypeEnum.ADDRESS:
      return HiOutlineLocationMarker;
    case IconTypeEnum.CANCEL:
      return MdOutlineCancelPresentation;
    case IconTypeEnum.SQUARE_CHECK:
      return BsSquare;
    case IconTypeEnum.SQUARE_FILL:
      return BsSquareFill;
    case IconTypeEnum.CLOCK:
      return MdOutlineTimer;
    case IconTypeEnum.ADD:
      return BiPlus;
    case IconTypeEnum.RIGHT:
      return BiChevronRight;
    case IconTypeEnum.DELETE:
      return BiTrash;
    case IconTypeEnum.NOTIFICATION:
      return IoIosNotificationsOutline;
    case IconTypeEnum.CALENDER:
      return AiOutlineCalendar;
    case IconTypeEnum.SEARCH:
      return AiOutlineSearch;
    case IconTypeEnum.QUESTION:
      return BsQuestionCircle;
    default:
      return BiPlus;
  }
};

export default getIconHelper;
