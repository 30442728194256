import { useContext, useEffect } from "react";
import { createPortal } from "react-dom";

import { AppContext } from "context";
import { IconTypeEnum } from "interfaces";

import { BoxModalInterface } from "./interfaces";
import getIconHelper from "../../helpers/getIconHelpers";

export default function ModalComponent({
                                         title,
                                         closeHandler,
                                         children,
                                         isCloseAuto,
                                         containerStyle,
                                       }: BoxModalInterface) {
  const { state, handlers } = useContext(AppContext);
  const CloseIcon = getIconHelper(IconTypeEnum.CLOSE);
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        closeHandler();
      }
    });
    return () => {
      document.removeEventListener("keydown", () => {});
    };
  }, []);

  useEffect(() => {
    if (state.isActionSuccess && isCloseAuto) {
      closeHandler();
      // handlers.setSuccess(false);
    }
  }, [state.isActionSuccess]);

  return createPortal(
      <div className="fixed flex items-start pt-20 justify-center h-screen w-screen left-0 top-0 z-[999] bg-gray-700 bg-opacity-70">
        <div className={`overflow-y-auto bg-white min-w-[40vw] rounded-md p-5 ${containerStyle}`}>
          {(title || typeof closeHandler === "function") && (
              <div className="flex justify-between items-center border-b-2 border-gray-100 pb-3">
                <span className={"font-bold text-[22px] text-black"}>{title}</span>
                <CloseIcon
                    className={"text-[40px] text-gray-200 cursor-pointer"}
                    onClick={() => closeHandler()}
                />
              </div>
          )}
          <div className="p-2 py-5 max-h-[80vh] min-h-[20vh] overflow-y-scroll">
            {children}
          </div>
        </div>
      </div>,
      document.getElementById("popup-root")
  );
}
