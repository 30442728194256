import React, { createContext, useContext } from "react";

import { AppContext } from "context";
import { Api } from "services";
import { apiUrl } from "utils";
import { DataContextInterface, QuestionInterface } from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "hooks";

import { HandlerCallbackInterface } from "interfaces";
import { FormikHelpers } from "formik/dist/types";
import {OrderInterface} from "../../interfaces/module/quiz/order.interface";
import {downloadCSV} from "../../utils/csv_downloader";
import {mapStatus} from "../../modules/studentModule/pages/ActiveOrder";

export const QuestionContext = createContext<
  DataContextInterface<QuestionInterface>
>({
  isLoading: false,
  allData: [],
  isDetailsLoading: false,
  totalArchiveDocs: 0,
  details: null,
  totalDocs: 0,
  getAllDataHandlers(query?: any) {},
  generateCSVHandler(query?: any) {},
  getDetailsHandler(itemId: string, isFromEdit?: boolean) {},
  deleteHandler(itemId: string, callback?: HandlerCallbackInterface) {},
  archiveHandler(
    itemId: string,
    status: boolean,
    callback?: HandlerCallbackInterface
  ) {},
  editDetailsHandler(
    values,
    actions: FormikHelpers<unknown>,
    callback?: HandlerCallbackInterface
  ) {},
});

function DataContextProvider({ children }) {
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();

  const {
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    setDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    setDetails,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, deleteApi } = Api();

  //  handler
  const contextHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);
        let res: any;
        res = await getApi(apiUrl.question.get_question, {
          ...query,
          ...paginationQuery,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemID: string, isFromEdit) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(
          apiUrl.question.get_questionDetails(itemID)
        );
        setDetails(res?.data);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    editDetailsHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoadingState(true);
        const shouldEdit = values?._id;
        let res;
        if (shouldEdit) {
          res = await putApi(apiUrl.question.put_question, values);
        } else {
          res = await postApi(apiUrl.question.post_question, values);
        }
        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccessState(true, res?.message);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoadingState(false);
      }
    },
    archiveHandler: async (
      itemID: string,
      status: boolean,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoadingState(true);
        const res = await putApi(
          apiUrl.question.put_archiveQuestion(itemID, status)
        );
        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoadingState(false);
      }
    },
    deleteHandler: async (itemsId, callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        handlers?.setLoadingState(true);
        const res: any = await deleteApi(
          apiUrl.question.delete_question(itemsId),
          {}
        );
        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccessState(true, res?.message);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoadingState(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    ...contextHandlers,
  };
  return (
    <QuestionContext.Provider value={contextValue}>
      {children}
    </QuestionContext.Provider>
  );
}

export default DataContextProvider;
