import React from "react";
import Skeleton from "react-loading-skeleton";

import { TableInterface, TableRowInterface } from "interfaces";
import "./styles.css";

const TableComponent = ({ loading, column, dataSource }: TableInterface) => {
  if (!loading?.isLoading && dataSource?.length < 1) {
    return <div className="text-center w-full bg-blue-50 p-1 ">No data</div>;
  }
  return (
    <>
      <table className="table rounded-full text-left  w-full text-[14px] rounded-md  table-auto ">
        <thead className="select-none w-full bg-gray-50 border-b-2 whitespace-nowrap">
          {column.map((e, index) => (
            <th
              scope="col"
              className="px-2 py-2 text-gray-700 text-[14px]"
              key={index}
            >
              {e?.title}
            </th>
          ))}
        </thead>
        <tbody>
          {loading?.isLoading
            ? Array.apply(0, new Array(loading?.loadingRow || 8)).map(
                (e, key) => {
                  return (
                    <TableRow
                      key={key}
                      index={key}
                      column={column}
                      isLoading={true}
                    />
                  );
                }
              )
            : dataSource?.map((item, key) => (
                <TableRow
                  key={key}
                  index={key}
                  column={column}
                  isLoading={false}
                  item={item}
                />
              ))}
        </tbody>
      </table>
    </>
  );
};

export default TableComponent;

export const TableRow = ({
  index,
  column,
  isLoading,
  item,
}: TableRowInterface) => {
  return (
    <tr className={`table_row `}>
      {column?.map((e, key) => (
        <td className="px-2 py-3" key={key}>
          {isLoading ? (
            <div data-testid={"loading-skeleton"}>
              <Skeleton />
            </div>
          ) : (
            typeof e?.render === "function" && e?.render(item, index)
          )}
        </td>
      ))}
    </tr>
  );
};
