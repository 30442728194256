import React from "react";
import {
    AdminContextProvider,
    AppContextProvider,
    ConfirmContextProvider,
    OrderHistoryContextProvider,
    StudentContextProvider,
} from "./context";
import {AuthRoutes} from "./routes";
import Layout from "layout";
import "./index.css";

const App = () => {
    return (
        <ConfirmContextProvider>
            <AppContextProvider>
                <AdminContextProvider>
                    <StudentContextProvider>
                        <OrderHistoryContextProvider>
                            <Layout>
                                <AuthRoutes/>
                            </Layout>
                        </OrderHistoryContextProvider>
                    </StudentContextProvider>
                </AdminContextProvider>
            </AppContextProvider>
        </ConfirmContextProvider>
    );
};

export default App;
