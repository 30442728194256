import React from "react";
import { Route, Routes } from "react-router-dom";

import { PrivateRouteComponent, PublicRouteComponent } from "./components";
import {
  AuthRoutes,
    StudentRoutes,
  UtilsPageRoutes,
  UserRoutes,
} from "../modules";
import {OrderHistoryPage} from "../modules/studentModule/pages";

export default function AuthRoute() {
  return (
    <Routes>
      <Route path="/" element={<PublicRouteComponent />}>
        {AuthRoutes()}
      </Route>
      <Route path="/" element={<PrivateRouteComponent />}>
        {UtilsPageRoutes()}
          <Route
              path={"order-history"}
              element={
                  <OrderHistoryPage/>
              }
          />
        {StudentRoutes()}

        {UserRoutes()}
      </Route>
    </Routes>
  );
}
