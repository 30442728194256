import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { AppContext, StudentContext } from "context";
import { Form, Formik, FieldArray } from "formik";
import {ShippingCountryList, StudentFormik} from "../helpers";
import {FormSubmitButtonsComponent, MyButton, MySelectField, MyTextField} from "components";
import {AdminRoleEnum, IconTypeEnum, ParamStringEnum} from "interfaces";
import { PageLinks } from "routes";

function EditStudentPage() {
  const navigate = useNavigate();
  const params = useParams<ParamStringEnum>();
  const editID = params.ID;
  const {
    handlers: { setLoadingState },
  } = useContext(AppContext);
  const { editDetailsHandler, getDetailsHandler, details } =
    useContext(StudentContext);
  useEffect(() => {
    (async () => {
      if (editID) {
        setLoadingState(true);
        await getDetailsHandler(editID);
        setLoadingState(false);
      }
    })();
  }, [editID]);
  const onSubmitHandler = (values, props) => {
    editDetailsHandler(values, props, {
      onSuccess(payload?: any) {
        navigate(PageLinks.student.view);
      },
    });
  };
  return (
    <PageTemplate title={editID ? "Edit Order" : "Add Order"}>
      <Formik
        validationSchema={StudentFormik.validationSchema}
        enableReinitialize
        initialValues={StudentFormik.initialValues(editID && details)}
        onSubmit={onSubmitHandler}
      >
        {({values, isSubmitting})=> {
          return <Form className={"flex flex-col gap-10"}>
            {/*Internation Tracking Detail*/}
            <div className={"grid gap-5 items-start grid-cols-2 sm:grid-cols-1 "}>
              <div className={"col-span-2 sm:col-span-1"}>
                <b className={"font-displayBold text-[20px] mb-4"}>International Tracking Details</b>
              </div>
              <MyTextField
                  name={"internationAwbNumber"}
                  label={"International AWB Number"}
                  placeholder={"eg: INT001-1212121"}
              />
              <MyTextField
                  name={"serviceProvider"}
                  label={"Service Provider Company Name"}
                  placeholder={"eg: DHL"}
              />
              <div className={"col-span-2"}>
                <MyTextField
                    name={"trackingLink"}
                    label={"Tracking Link"}
                    placeholder={"eg: https://www.dhl.com/tracking=WWW09402342"}
                />
              </div>
              <MyTextField
                  name={"sellingAgentName"}
                  label={"Selling Agent Name"}
                  placeholder={"eg: ABC Logistics Pvt. Ltd."}
              />
              <MyTextField
                  name={"sellingAgentCode"}
                  label={"Selling Agent Code"}
                  type={"number"}
                  isNumberOnly
                  placeholder={"eg: 754"}
              />


            </div>
            {/*tracking*/}
            <div className={"grid gap-5 items-start grid-cols-2 sm:grid-cols-1 "}>
              <div className={"col-span-2 sm:col-span-1"}>
                <b className={"font-displayBold text-[20px] mb-4"}>Tracking Details</b>
              </div>
              <MyTextField
                  name={"waybillNumber"}
                  isRequired
                  label={"Way Bill Number"}
                  placeholder={"eg: 001-2311-1234"}
              />
              <div>

              </div>

              <MyTextField
                  name={"buyingAgentName"}
                  label={"Buying Agent Name"}
                  placeholder={"eg: Infinity Logistics Pvt. Ltd."}
              />
              <MyTextField
                  name={"buyingAgentCode"}
                  type={"number"}
                  isNumberOnly
                  label={"Buying Agent Code"}
                  placeholder={"eg: 754"}
              />
            </div>
            {/*Shipping Detail*/}
            <div className={"grid gap-5 items-start grid-cols-2 sm:grid-cols-1 "}>
              <div className={"col-span-2 sm:col-span-1"}>
                <b className={"font-displayBold text-[20px] mb-4"}>Shipper Details</b>
              </div>
              <MyTextField
                  name={"shipper.firstName"}
                  isRequired
                  label={"First Name"}
                  placeholder={"eg: John"}
              />
              <MyTextField
                  name={"shipper.lastName"}
                  label={"Last Name"}
                  placeholder={"eg: Doe"}
              />
              <div className={"col-span-2 sm:col-span-1"}>

                <MyTextField
                    name={"shipper.address"}
                    label={"Address"}
                    placeholder={"eg: Shantinagar-31, Kathmandu"}
                />
              </div>
              <MyTextField
                  name={"shipper.phone"}
                  label={"Phone Number"}
                  placeholder={"eg: 9841000000"}
              />
              <MyTextField
                  name={"shipper.email"}
                  label={"Email"}
                  placeholder={"eg: example@mail.com"}
              />
            </div>
            {/*consignee Detail*/}
            <div className={"grid gap-5 items-start grid-cols-2 sm:grid-cols-1 "}>
              <div className={"col-span-2 sm:col-span-1"}>
                <b className={"font-displayBold text-[20px] mb-4"}>Consignee Details</b>
              </div>
              <MyTextField
                  name={"consignee.firstName"}
                  isRequired
                  label={"First Name"}
                  placeholder={"eg: John"}
              />
              <MyTextField
                  name={"consignee.lastName"}
                  label={"Last Name"}
                  placeholder={"eg: Doe"}
              />


              <MyTextField
                  name={"consignee.phone"}
                  label={"Phone Number"}
                  isRequired
                  placeholder={"eg: 9841000000"}
              />
              <MyTextField
                  name={"consignee.secondaryPhone"}
                  label={"Secondary Phone Number"}
                  placeholder={"eg: 9841000001"}
              />
              <div className={"col-span-2 sm:col-span-1"}>

                <MyTextField
                    name={"consignee.address"}
                    label={"Address"}
                    placeholder={"eg: Shantinagar-31, Kathmandu"}
                />
              </div>

              <MySelectField
                  name={"consignee.country"}
                  label={"Destination Country"}
                  placeholder={"eg: Australia"}
                  option={{
                    selectOptions: ShippingCountryList.map(each => ({
                      label: each,
                      value: each,
                    })),
                  }}
              />
              <MyTextField
                  name={"consignee.email"}
                  label={"Email"}
                  placeholder={"eg: example@mail.com"}
              />
            </div>
            {/*package detail*/}
            <div className={"grid gap-5 items-start grid-cols-3 sm:grid-cols-1 "}>
              <div className={"col-span-3 sm:col-span-1"}>
                <b className={"font-displayBold text-[20px] mb-4"}>Package Details</b>
              </div>
              <MyTextField
                  name={"package.quantity"}
                  isRequired
                  isNumberOnly
                  label={"Quantity"}
                  placeholder={"eg: 2"}
              />
              <MyTextField
                  name={"package.weight"}
                  label={"Weight (in kG)"}
                  placeholder={"eg: 0.25 KG"}
              />
              <MyTextField
                  name={"package.commodity"}
                  label={"Commodity"}
                  placeholder={"eg: Makeup Kits"}
              />
            </div>
            {/*volumetric weight*/}
            <div>
              <div className={"col-span-2 sm:col-span-1  mb-4"}>
                <b className={"font-displayBold text-[20px] mb-4"}>Volumetric Weight Details</b>
              </div>
              {/* FieldArray for PackageWeightDetail */}
              <FieldArray
                  name={"packageWeightDetail"}
                  render={({ push, remove }) => {
                    return (
                        <div className={"flex flex-col gap-5"}>
                          {values?.packageWeightDetail?.map((e, index) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                      `grid grid-cols-3 sm:grid-cols-1 gap-5 items-start justify-center border-gray-200 ${index!==0 ? 'border-t pt-5  ' : ''}`
                                    }
                                >
                                  <MyTextField
                                      isNumberOnly
                                      type='number'
                                      label={"Length (in CM)"}
                                      placeholder={"eg: 5"}
                                      name={`packageWeightDetail.${index}.length`}
                                  />
                                  <MyTextField
                                      isNumberOnly
                                      type='number'
                                      label={"Breadth (in CM)"}
                                      placeholder={"eg: 5"}
                                      name={`packageWeightDetail.${index}.breadth`}
                                  />
                                  <MyTextField
                                      isNumberOnly
                                      type='number'
                                      label={"Height (in CM)"}
                                      placeholder={"eg: 5"}
                                      name={`packageWeightDetail.${index}.height`}
                                  />
                                  <MyTextField
                                      isNumberOnly
                                      type='number'
                                      label={"Quantity (in Pcs)"}
                                      placeholder={"eg: 5"}
                                      name={`packageWeightDetail.${index}.quantity`}
                                  />

                                  <div className={"flex items-center gap-10 mt-8"}>
                                    <div>
                                      Volumetric Weight: {(()=> {
                                      const eachBody = values?.packageWeightDetail[index];
                                      const weight = (eachBody?.length??0) *  (eachBody?.breadth??0) * (eachBody?.height??0) / 5000;
                                      return (weight * (eachBody?.quantity??0))??0;
                                    })()} KG
                                    </div>
                                  </div>
                                  <div className={"flex items-center gap-10 mt-8"}>
                                    <div>
                                      <MyButton
                                          colorType={"danger"}
                                          isOutline
                                          name={"Remove"}
                                          onClick={() => remove(index)}
                                      />
                                    </div>
                                  </div>
                                </div>
                            );
                          })}
                          <div>
                            <MyButton
                                isOutline
                                name={"Add More"}
                                onClick={() => {
                                  push((values?: any)=>{
                                    return {
                                      length: values?.number??0,
                                      breadth: values?.number??0,
                                      height: values?.number??0,
                                      quantity: values?.number??0,
                                      volumetricWeight: values?.number??0,
                                    }
                                  });
                                }}
                                iconType={IconTypeEnum.ADD}
                            />
                          </div>
                        </div>
                    );
                  }}
              />
            </div>
            <div className={"grid gap-5 items-start grid-cols-2 sm:grid-cols-1 "}>
              <div className={"col-span-2 sm:col-span-1"}>
                <b className={"font-displayBold text-[20px] mb-4"}>More Information</b>
              </div>
              <MyTextField
                  name={"bookedDate"}
                  type={"date"}
                  label={"Booking Date"}
              />
              <MyTextField
                  // dateValidation={{
                  //   pastOnly: true,
                  // }}
                  name={"expectedDeliveryDate"}
                  type={"date"}
                  label={"Expected Delivery Date"}
              />
              <MyTextField
                  name={"package.worth"}
                  isNumberOnly
                  type={"number"}
                  label={"Value (In Rs.)"}
                  placeholder={"eg: Rs 2500"}
              />
              <MyTextField
                  name={"specialInstruction"}
                  label={"Special Instruction From Client"}
                  placeholder={"eg: Handle with care"}
              />
            </div>
            <FormSubmitButtonsComponent haveSubmitButton haveCancelButton />
          </Form>
        }}
      </Formik>
    </PageTemplate>
  );
}

export default EditStudentPage;
