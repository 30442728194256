import React, {useContext, useEffect} from "react";
import {PageTemplate} from "templates";
import {AppContext, StudentContext} from "context";
import {MyButton, MyMoreOptionButton, PaginationComponent, TableComponent,} from "components";
import {IconTypeEnum, TableColumnInterface,} from "interfaces";
import {Outlet, useNavigate} from "react-router-dom";
import {PageLinks} from "routes";
import {usePaginationQueryHooks} from "hooks";
import {OrderInterface} from "../../../interfaces/module/quiz/order.interface";
import {mapStatusWithLabel} from "../../../helpers/HandlerHelpers";
import {Form, Formik} from "formik";

export const mapStatus = (status) => {
    if (!status) {
        return "N/A"
    }
    const statusOrder = [
        'SHIPMENT_DELIVERED',
        'SHIPMENT_OUT_FOR_DELIVERY',
        'SHIPMENT_IN_DESTINATION_WAREHOUSE',
        'SHIPMENT_ARRIVED_IN_DESTINATION',
        'SHIPMENT_DEPARTED_TRANSIT_3',
        'SHIPMENT_IN_TRANSIT_3',
        'SHIPMENT_DEPARTED_TRANSIT_2',
        'SHIPMENT_IN_TRANSIT_2',
        'SHIPMENT_DEPARTED_TRANSIT_1',
        'SHIPMENT_IN_TRANSIT',
        'SHIPMENT_DEPARTED',
        'SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE',
        'SHIPMENT_IN_CUSTOM_PROCESS',
        'SHIPMENT_PICKED_UP',
        'SHIPMENT_BOOKED',
    ];
    // Find the first status with isDone set to true
    const activeStatus = statusOrder.find((key) => status[key]?.isDone ?? false);
    return mapStatusWithLabel(activeStatus);
}

const getFirstWord = (sentence: string): string | null => {
    return (sentence ?? '').split(' ')?.[0] ?? null;
}

function StudentPage() {
    const {generateCSVHandler, getAllDataHandlers, deleteHandler, isLoading, allData, totalDocs} =
        useContext(StudentContext);
    const navigate = useNavigate();
    const {calcSNHandler, currentPage} = usePaginationQueryHooks();
    const { handlers } = useContext(AppContext);


    useEffect(() => {
        getAllDataHandlers();
    }, [currentPage]);

    function StudentTable(): TableColumnInterface<OrderInterface>[] {
        return [
            {
                title: "SN",
                render(renderData, key: number) {
                    return <div>{calcSNHandler(key)}.</div>;
                },
            },
            {
                title: "International AWB",
                render(renderData) {
                    return (
                        <div className={"flex flex-col items-start gap-1"}>
                            <span>{`${renderData?.serviceProvider || ""}`}</span>
                            {renderData?.trackingLink ?
                                <a target={'_blank'} className={"hover:text-gray-500 text-blue-500"}
                                   href={renderData?.trackingLink}><span>{(renderData?.internationAwbNumber || "").toUpperCase()}</span></a> :
                                <span>{(renderData?.internationAwbNumber || "").toUpperCase()}</span>
                            }
                        </div>
                    );
                },
            },
            {
                title: "WayBill Number",
                render(renderData) {
                    return (
                        <div className={"flex flex-col items-start gap-1"}>
                            <span>{(renderData?.waybillNumber ?? '').toUpperCase()}</span>
                        </div>
                    );
                },
            },
            {
                title: "Agents",
                render(renderData) {
                    return (
                        <div className={"flex flex-col items-start gap-1"}>
                            <span>Selling: {getFirstWord(renderData?.sellingAgentName) ?? 'N/A'}</span>
                            <span>Buying: {getFirstWord(renderData?.buyingAgentName) ?? 'N/A'}</span>
                        </div>
                    );
                },
            },

            {
                title: "Shipper",
                render(renderData) {
                    return (
                        <div className={"flex flex-col items-start gap-1"}>
                            <span>{`${renderData?.shipper?.firstName || ""} ${renderData?.shipper?.lastName || ""}`}{renderData?.shipper?.phone && ','} {`${renderData?.shipper?.phone || ""}`}</span>
                            <span>{`${renderData?.shipper?.address || ""}`}</span>
                        </div>
                    );
                },
            },
            {
                title: "Consignee",
                render(renderData) {
                    return (
                        <div className={"flex flex-col items-start gap-1"}>
                            <span>{`${renderData?.consignee?.firstName || ""} ${renderData?.consignee?.lastName || ""}`}{renderData?.consignee?.phone && ','}</span>
                            {/*<span>{`${renderData?.consignee?.address || ""}`}</span>*/}
                            {renderData?.consignee?.country && <span>{`${renderData?.consignee?.country || ""}`}</span>}
                        </div>
                    );
                },
            },
            {
                title: "Status",
                render(renderData) {
                    return (
                        <div className={"flex flex-col items-start gap-1"}>
                            {mapStatus(renderData?.status)}
                        </div>
                    );
                },
            },
            // {
            //   title: "Wallet ID",
            //   render(renderData) {
            //     return (
            //       <div className={"flex items-start "}>
            //         <span>{renderData?.walletID}</span>
            //       </div>
            //     );
            //   },
            // },
            {
                title: "Action",
                render(renderData) {
                    return (
                        <div>
                            <MyMoreOptionButton
                                options={[
                                    {
                                        name: "Copy Detail",
                                        handler: () => {
                                            navigator.clipboard.writeText(`
                                            Waybill No: ${renderData.waybillNumber},
                                            Shipper ${renderData?.shipper?.firstName || ""} ${renderData?.shipper?.lastName || ""},
                                            Consignee ${renderData?.consignee?.firstName || ""} ${renderData?.consignee?.lastName || ""},
                                            Status: ${mapStatus(renderData?.status)},
                                            Tracking: https://hyre.com.np/tracking?trackingNumber=${renderData.waybillNumber}
                                            `)
                                                .then(() => {
                                                    handlers?.setSuccessState(true, 'Text copied to clipboard successfully');
                                                    console.log('Text copied to clipboard successfully');
                                                    // You can also show a success message here
                                                })
                                                .catch(err => {
                                                    handlers?.setSuccessState(false, 'Error copying text to clipboard.');
                                                    console.error('Error copying text to clipboard:', err);
                                                    // Handle error, maybe show a message to the user
                                                });
                                        },
                                    },
                                    {
                                        name: "Edit",
                                        handler: () => {
                                            navigate(PageLinks.student.edit(renderData?._id));
                                        },
                                    },
                                    {
                                        name: "Change Status",
                                        handler: () => {
                                            navigate(PageLinks.student.change_status(renderData?._id));
                                        },
                                    },
                                    {
                                        name: "Delete",
                                        handler() {
                                            deleteHandler(renderData?._id, {
                                                onSuccess(payload?: any): any {
                                                    getAllDataHandlers();
                                                },
                                            });
                                        },
                                    },
                                ]}
                            />
                        </div>
                    );
                },
            },
        ];
    }

    const handleSearch = (keyword: string) => {
        if(keyword?.length) {
            getAllDataHandlers({
                searchKeyword: keyword
            })
        }else {
            getAllDataHandlers();
        }

    }
    return (
        <PageTemplate
            title={"Active Orders"}
            exportXlsx={{
                generateHandler: () => generateCSVHandler(),
            }}
            rightChildren={
                <div className={"w-full flex flex-row items-center gap-3"}>
                    <div className={"flex-1"}>
                        <Formik
                            onSubmit={(values)=>{
                                handleSearch(values.searchKeyword);
                            }}
                            initialValues={{searchKeyword: ""}}
                        >
                            {({values, handleChange}) => (
                                <Form
                                    className="w-[500px] md:w-[400px] sm:w-[80vw] flex flex-row items-center rounded-4px overflow-hidden bg-opacity-80 bg-white shadow-md ">
                                    <input
                                        type="text"
                                        placeholder="Enter AWB, Name"
                                        className="border-none px-4 py-2 text-base outline-none tracking-wider w-full sm:max-w-[80vw]"
                                        name="searchKeyword"
                                        value={values.searchKeyword}
                                        onChange={handleChange}
                                    />
                                    <button
                                        type="submit"
                                        className="px-4 py-2 bg-blue-500 text-white cursor-pointer"
                                    >
                                        Search
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                    <MyButton
                        name={"Add Order"}
                        onClick={() => navigate(PageLinks.student.create)}
                        iconType={IconTypeEnum.ADD}
                    />
                </div>
            }
        >
            <TableComponent
                column={StudentTable()}
                dataSource={allData}
                loading={{
                    isLoading: isLoading,
                }}
            />
            <PaginationComponent totalDocs={totalDocs}/>
            <Outlet/>
        </PageTemplate>
    );
}

export default StudentPage;
