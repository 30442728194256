const PageLinks = {
  auth: {
    login: "/",
  },
  profile: {
    profileDetails: "/profile",
    changePassword: "/profile/change-password",
  },
  student: {
    view: "/order",
    history: "/order-history",
    create: "/order/create",
    edit: (id: string) => `/order/edit/${id}`,
    change_status: (id: string) => `/order/modal/status/edit/${id}`,
    details: (id: string) => `/order/details/${id}`,
  },
  web: {
    track: '/'
  },
  user: {
    view: "/users",
    create: "/users/create",
    edit: (id: string) => `/users/edit/${id}`,
    details: (id: string) => `/users/details/${id}`,
  },
  questions: {
    view: "/questions",
    create: "/questions/create",
    edit: (id: string) => `/questions/edit/${id}`,
  },
  quiz: {
    view: "/quiz",
    create: "/quiz/create",
    edit: (id: string) => `/quiz/edit/${id}`,
  },
  quizSession: {
    pendingQuiz: "/quiz-session",
    playQuiz: (id: string) => `/quiz-session/play/${id}`,
  },
  report: {
    quizReport: "/reports",
  },
};

export default PageLinks;
