import React, { createContext, useContext } from "react";

import { AppContext } from "context";
import { Api } from "services";
import { apiUrl } from "utils";
import { StudentInterface, DataContextInterface } from "interfaces";
import {
  useConfirmHook,
  useDataContextHooks,
  usePaginationQueryHooks,
} from "hooks";

import { HandlerCallbackInterface } from "interfaces";
import { FormikHelpers } from "formik/dist/types";
import {OrderInterface} from "../../interfaces/module/quiz/order.interface";
import {downloadCSV} from "../../utils/csv_downloader";
import {mapStatus} from "../../modules/studentModule/pages/ActiveOrder";

export const StudentContext = createContext<
  DataContextInterface<OrderInterface>
>({
  isLoading: false,
  allData: [],
  isDetailsLoading: false,
  totalArchiveDocs: 0,
  details: null,
  totalDocs: 0,
  getAllDataHandlers(query?: any) {},
  generateCSVHandler(query?: any) {},
  getDetailsHandler(itemId: string, isFromEdit?: boolean) {},
  deleteHandler(itemId: string, callback?: HandlerCallbackInterface) {},
  editDetailsHandler(
    values,
    actions: FormikHelpers<unknown>,
    callback?: HandlerCallbackInterface
  ) {},
});

function DataContextProvider({ children }) {
  const { confirm } = useConfirmHook();
  const { isArchive, paginationQuery } = usePaginationQueryHooks();

  const {
    totalArchiveDocs,
    setTotalArchiveDocs,
    isDetailsLoading,
    setDetailsLoading,
    details,
    setLoading,
    isLoading,
    setTotalDocs,
    totalDocs,
    setDetails,
    allData,
    setAllData,
  } = useDataContextHooks();

  const { handlers } = useContext(AppContext);
  const { getApi, postApi, putApi, deleteApi } = Api();

  //  handler
  const contextHandlers = {
    getAllDataHandlers: async (query) => {
      try {
        setLoading(true);

        let res: any;
        res = await getApi(apiUrl.student.get_student, {
          ...query,
          ...paginationQuery,
        });
        if (isArchive) {
          setTotalArchiveDocs(res?.data?.totalDocs);
        } else {
          setTotalDocs(res?.data?.totalDocs);
        }
        setAllData(res?.data?.docs);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    generateCSVHandler: async (query) => {
      try {
        setLoading(true);
        let res: any = await getApi(apiUrl.student.get_student, {
          ...query,
        });
        const csvAllData = (res?.data?.docs ?? []) as OrderInterface[];
        await downloadCSV(
            'active_order',
            [
              'SN',
              'International AWB',
              'WayBill Number',
              'Selling Agents',
              'Buying Agents',
              'Shipper Name',
              'Shipper Phone',
              'Shipper Address',
              'Consignee Name',
              'Consignee Phone',
              'Consignee Address',
              'Status',
            ], csvAllData.map((renderData, index) => ([
              index + 1,
              (renderData?.internationAwbNumber || "").toUpperCase(),
              (renderData?.waybillNumber ?? '').toUpperCase(),
              renderData?.sellingAgentName ?? "",
              renderData?.buyingAgentName ?? "",
              `${renderData?.shipper?.firstName || ""} ${renderData?.shipper?.lastName || ""}`,
              renderData?.shipper?.phone ?? '',
              renderData?.shipper?.address,
              `${renderData?.consignee?.firstName || ""} ${renderData?.consignee?.lastName || ""}`,
              renderData?.consignee?.phone ?? '',
              renderData?.consignee?.country,
              mapStatus(renderData?.status),
            ])));
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setLoading(false);
      }
    },
    getDetailsHandler: async (itemID: string, isFromEdit) => {
      try {
        if (!isFromEdit) {
          setDetailsLoading(true);
          setDetails(null);
        }
        const res: any = await getApi(
          apiUrl.student.get_studentDetails(itemID)
        );
        setDetails(res?.data);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
      } finally {
        setDetailsLoading(false);
      }
    },
    editDetailsHandler: async (
      values,
      props,
      callback: HandlerCallbackInterface
    ) => {
      try {
        handlers?.setLoadingState(true);
        const shouldEdit = values?._id;
        let res;
        if (shouldEdit) {
          res = await putApi(apiUrl.student.put_student, values);
        } else {
          res = await postApi(apiUrl.student.post_student, values);
        }
        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccessState(true, res?.message);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoadingState(false);
      }
    },
    deleteHandler: async (itemsId, callback: HandlerCallbackInterface) => {
      try {
        const isConfirm = await confirm("Are you sure?");
        if (!isConfirm) return;
        handlers?.setLoadingState(true);
        const res: any = await deleteApi(
          apiUrl.student.delete_student(itemsId),
          {}
        );
        if (typeof callback?.onSuccess === "function") {
          await callback.onSuccess(res);
        }
        handlers?.setSuccessState(true, res?.message);
      } catch (err) {
        handlers?.setErrorState(true, err?.message);
        if (typeof callback?.onError === "function") {
          await callback.onError(err);
        }
      } finally {
        handlers?.setLoadingState(false);
      }
    },
  };

  const contextValue = {
    totalDocs,
    totalArchiveDocs,
    allData,
    details,
    isDetailsLoading,
    isLoading,
    ...contextHandlers,
  };
  return (
    <StudentContext.Provider value={contextValue}>
      {children}
    </StudentContext.Provider>
  );
}

export default DataContextProvider;
