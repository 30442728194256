import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {StudentContext} from "../../../context";
import {BoxModal, MyCheckInput, MyTextField} from "../../../components";
import Skeleton from "react-loading-skeleton";
import FormConcludeButtons from "../../../components/form/FormSubmitButtonsComponent";
import {IconTypeEnum, ParamStringEnum} from "../../../interfaces";
import {Simulate} from "react-dom/test-utils";
import * as yup from "yup";
import {Form, Formik, useFormikContext} from "formik";
import {OrderInterface, ShipmentStatus} from "../../../interfaces/module/quiz/order.interface";
import {OrderStatusFormik} from "../helpers";
import MyRadioInput from "../../../components/myInputs/MyRadioInput";
import ToggleComponent from "../../../components/ToggleComponent";

function ChangeStatusModal() {
    const [isLoading, setLoading] = useState(true);
    const navigate = useNavigate();
    const params = useParams<ParamStringEnum>();
    const { getDetailsHandler, editDetailsHandler, details } =
        useContext(StudentContext);
    useEffect(() => {
        (async () => {
            setLoading(true);
            await getDetailsHandler(params?.ID);
            setLoading(false);
        })();
    }, []);
    const onSubmitHandler = async (values, props) => {
        let payload = {
            _id: params?.ID,
            status: values?.status
        };
        await editDetailsHandler(payload, null, {
            async onSuccess(payload?: any) {
                await getDetailsHandler(params?.ID);
            },
        });
    };

const EachStatusComponent = ({detail, name}: {detail: ShipmentStatus, name: string}) => {
    const [radioChecked, setToggleOpen] = useState(false);

    const onRadioClick = (radioChecked: boolean) => {
        // radio click
        setToggleOpen(radioChecked)
    }
    const radioInput = <MyRadioInput radioButtonType={'check'} name={`status.${name}.isDone`} label={(name??'').replaceAll('_', ' ')} />;
    return detail?.isDone ?
        <ToggleComponent  title={radioInput}>

        {detail?.isDone && <div className="grid gap-5 items-start grid-cols-2 sm:grid-cols-1 border-b-2 border-gray-100 pb-3">
            <div className={"col-span-2 sm:col-span-1"}>
                <MyTextField
                    name={`status.${name}.remarks`}
                    label={"Remarks"}
                    placeholder={""}
                />
            </div>
            <MyTextField
                label={"Recorded Date"}
                name={`status.${name}.doneAt`}
                type={"date"}
            />
            <MyTextField
                label={"Recorded Time"}
                name={`status.${name}.doneAtTime`}
                placeholder={"11:02 AM"}
            />
        </div>}
    </ToggleComponent> : radioInput;
}
    return (
        <>
            <BoxModal
                title={"Change Status of " + details?.waybillNumber}
                isCloseAuto
                containerStyle={'w-[80vw] h-[80vh] '}
                closeHandler={() => navigate(-1)}
            >
                {isLoading &&
                    new Array(8).fill("").map((e, key) => {
                        return <Skeleton key={key} className={"w-[150px]"} />;
                    })}
                {!isLoading && <Formik
                    enableReinitialize
                    onSubmit={onSubmitHandler}
                    initialValues={OrderStatusFormik.initialValues(params.ID && details)}
                    validationSchema={OrderStatusFormik.validationSchema}
                >
                    {({ isSubmitting, values }) => {
                        return (
                            <Form className={"flex flex-col justify-between gap-5"}>
                                <div className={'grid gap-5'}>
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_BOOKED} name={'SHIPMENT_BOOKED'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_PICKED_UP} name={'SHIPMENT_PICKED_UP'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_IN_CUSTOM_PROCESS} name={'SHIPMENT_IN_CUSTOM_PROCESS'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE} name={'SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_DEPARTED} name={'SHIPMENT_DEPARTED'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_IN_TRANSIT} name={'SHIPMENT_IN_TRANSIT'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_DEPARTED_TRANSIT_1} name={'SHIPMENT_DEPARTED_TRANSIT_1'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_IN_TRANSIT_2} name={'SHIPMENT_IN_TRANSIT_2'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_DEPARTED_TRANSIT_2} name={'SHIPMENT_DEPARTED_TRANSIT_2'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_IN_TRANSIT_3} name={'SHIPMENT_IN_TRANSIT_3'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_DEPARTED_TRANSIT_3} name={'SHIPMENT_DEPARTED_TRANSIT_3'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_ARRIVED_IN_DESTINATION} name={'SHIPMENT_ARRIVED_IN_DESTINATION'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_IN_DESTINATION_WAREHOUSE} name={'SHIPMENT_IN_DESTINATION_WAREHOUSE'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_OUT_FOR_DELIVERY} name={'SHIPMENT_OUT_FOR_DELIVERY'} />
                                    <EachStatusComponent detail={(values as OrderInterface)?.status?.SHIPMENT_DELIVERED} name={'SHIPMENT_DELIVERED'} />
                                </div>
                                <FormConcludeButtons
                                    haveCancelButton
                                    cancelButton={{
                                        title: "Cancel",
                                    }}
                                    submitButton={{
                                        title: "Submit",
                                        isLoading: isSubmitting,
                                    }}
                                />
                            </Form>
                        );
                    }}
                </Formik>}
            </BoxModal>
        </>
    );

}

export default ChangeStatusModal;
