import * as yup from "yup";
import { StudentInterface } from "interfaces";
import {OrderInterface} from "../../interfaces/module/quiz/order.interface";

export const StudentFormik = {
  initialValues: (values?: OrderInterface) => ({
    ...(values?._id && { _id: values?._id }),
    internationAwbNumber: values?.internationAwbNumber || '',
    serviceProvider: values?.serviceProvider || '',
    trackingLink: values?.trackingLink || '',
    sellingAgentCode: values?.sellingAgentCode || '',
    sellingAgentName: values?.sellingAgentName || '',
    buyingAgentName: values?.buyingAgentName || '',
    buyingAgentCode: values?.buyingAgentCode || '',
    waybillNumber: values?.waybillNumber || '',
    shipper: {
      firstName: values?.shipper?.firstName || '',
      lastName: values?.shipper?.lastName || '',
      address: values?.shipper?.address || '',
      phone: values?.shipper?.phone || '',
      email: values?.shipper?.email || '',
    },
    consignee: {
      firstName: values?.consignee?.firstName || '',
      lastName: values?.consignee?.lastName || '',
      address: values?.consignee?.address || '',
      country: values?.consignee?.country || '',
      phone: values?.consignee?.phone || '',
      secondaryPhone: values?.consignee?.secondaryPhone || '',
      email: values?.consignee?.email || '',
    },
    package: {
      quantity: values?.package?.quantity || '',
      weight: values?.package?.weight || '',
      commodity: values?.package?.commodity || '',
      worth: values?.package?.worth || '',
    },
    packageWeightDetail: (values?.packageWeightDetail??[]).map(each => {
      each.volumetricWeight = each.volumetricWeight || 0;
      each.length = each.length || 0;
      each.breadth = each.breadth || 0;
      each.height = each.height || 0;
      each.quantity = each.quantity || 0;
      return each;
    }),
    expectedDeliveryDate: values?.expectedDeliveryDate || "", // Assuming default value is null
    specialInstruction: values?.specialInstruction || '',
    freightCharge: values?.freightCharge || '', // Assuming default value is 0
    bookedDate: values?.bookedDate ||"", // Assuming default value is null
  }),
  validationSchema: yup.object().shape({
    internationAwbNumber: yup.string(),
    serviceProvider: yup.string(),
    trackingLink: yup.string(),
    sellingAgentCode: yup.number(),
    sellingAgentName: yup.string(),
    buyingAgentName: yup.string(),
    buyingAgentCode: yup.number(),
    totalVolumetricWeight: yup.number(),
    waybillNumber: yup.string().required("required"),

    shipper: yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string(),
      address: yup.string(),
      phone: yup.string(),
      email: yup.string(),
    }),
    consignee: yup.object().shape({
      firstName: yup.string().required("required"),
      lastName: yup.string(),
      address: yup.string(),
      country: yup.string().required("required"),
      phone: yup.string().required("required"),
      secondaryPhone: yup.string(),
      email: yup.string(),
    }),
    package: yup.object().shape({
      quantity: yup.number().required("required"),
      length: yup.number(),
      breadth: yup.number(),
      height: yup.number(),
      weight: yup.number(), // Assuming weight is required
      commodity: yup.string(),
      worth: yup.number(),
    }),
    packageWeightDetail: yup.array().of(yup.object().shape({
      quantity: yup.number().required("required"),
      length: yup.number(),
      breadth: yup.number(),
      height: yup.number(),
      volumetricWeight: yup.number(), // Assuming weight is required
    })),
    agentCode: yup.number(),
    source: yup.string(),
    expectedDeliveryDate: yup.date(),
    specialInstruction: yup.string(),
    freightCharge: yup.number(),
    bookedDate: yup.date(),
  }),
};
const eachStatusValidation = yup
    .object()
    .shape({
      isDone: yup.boolean(),
      remarks: yup.string(),
      doneAtTime: yup.string(),
    })

export const getDateTimeInNepalTimezone = (dateTime: Date) => {

  if(dateTime) {
    const nepalTimeOptions: any = {
      timeZone: 'Asia/Kathmandu',
      hour12: false,
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    const formattedDateTime = dateTime.toLocaleString('en-US', nepalTimeOptions);
    console.log('date', dateTime);
    console.log('new Date(formattedDateTime)', new Date(formattedDateTime));

    return new Date(formattedDateTime).toISOString()
  }
  return ""
}
export const OrderStatusFormik = {
  initialValues: (values?: OrderInterface) => ({
    ...(values?._id && { _id: values?._id }),
    status: {
      SHIPMENT_BOOKED: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_BOOKED?.doneAt) || "", isDone: values?.status?.SHIPMENT_BOOKED?.isDone??false, remarks: values?.status?.SHIPMENT_BOOKED?.remarks || "", doneAtTime: values?.status?.SHIPMENT_BOOKED?.doneAtTime || "",},
      SHIPMENT_PICKED_UP: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_PICKED_UP?.doneAt) || "", isDone: values?.status?.SHIPMENT_PICKED_UP?.isDone??false, remarks: values?.status?.SHIPMENT_PICKED_UP?.remarks || "", doneAtTime: values?.status?.SHIPMENT_PICKED_UP?.doneAtTime || "",},
      SHIPMENT_IN_CUSTOM_PROCESS: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_IN_CUSTOM_PROCESS?.doneAt) || "", isDone: values?.status?.SHIPMENT_IN_CUSTOM_PROCESS?.isDone??false, remarks: values?.status?.SHIPMENT_IN_CUSTOM_PROCESS?.remarks || "", doneAtTime: values?.status?.SHIPMENT_IN_CUSTOM_PROCESS?.doneAtTime || "",},
      SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE?.doneAt) || "", isDone: values?.status?.SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE?.isDone??false, remarks: values?.status?.SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE?.remarks || "", doneAtTime: values?.status?.SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE?.doneAtTime || "",},
      SHIPMENT_DEPARTED: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_DEPARTED?.doneAt) || "", isDone: values?.status?.SHIPMENT_DEPARTED?.isDone??false, remarks: values?.status?.SHIPMENT_DEPARTED?.remarks || "", doneAtTime: values?.status?.SHIPMENT_DEPARTED?.doneAtTime || "",},
      SHIPMENT_IN_TRANSIT: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_IN_TRANSIT?.doneAt) || "", isDone: values?.status?.SHIPMENT_IN_TRANSIT?.isDone??false, remarks: values?.status?.SHIPMENT_IN_TRANSIT?.remarks || "", doneAtTime: values?.status?.SHIPMENT_IN_TRANSIT?.doneAtTime || "",},
      SHIPMENT_DEPARTED_TRANSIT_1: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_DEPARTED_TRANSIT_1?.doneAt) || "", isDone: values?.status?.SHIPMENT_DEPARTED_TRANSIT_1?.isDone??false, remarks: values?.status?.SHIPMENT_DEPARTED_TRANSIT_1?.remarks || "", doneAtTime: values?.status?.SHIPMENT_DEPARTED_TRANSIT_1?.doneAtTime || "",},
      SHIPMENT_IN_TRANSIT_2: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_IN_TRANSIT_2?.doneAt) || "", isDone: values?.status?.SHIPMENT_IN_TRANSIT_2?.isDone??false, remarks: values?.status?.SHIPMENT_IN_TRANSIT_2?.remarks || "", doneAtTime: values?.status?.SHIPMENT_IN_TRANSIT_2?.doneAtTime || "",},
      SHIPMENT_DEPARTED_TRANSIT_2: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_DEPARTED_TRANSIT_2?.doneAt) || "", isDone: values?.status?.SHIPMENT_DEPARTED_TRANSIT_2?.isDone??false, remarks: values?.status?.SHIPMENT_DEPARTED_TRANSIT_2?.remarks || "", doneAtTime: values?.status?.SHIPMENT_DEPARTED_TRANSIT_2?.doneAtTime || "",},
      SHIPMENT_IN_TRANSIT_3: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_IN_TRANSIT_3?.doneAt) || "", isDone: values?.status?.SHIPMENT_IN_TRANSIT_3?.isDone??false, remarks: values?.status?.SHIPMENT_IN_TRANSIT_3?.remarks || "", doneAtTime: values?.status?.SHIPMENT_IN_TRANSIT_3?.doneAtTime || "",},
      SHIPMENT_DEPARTED_TRANSIT_3: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_DEPARTED_TRANSIT_3?.doneAt) || "", isDone: values?.status?.SHIPMENT_DEPARTED_TRANSIT_3?.isDone??false, remarks: values?.status?.SHIPMENT_DEPARTED_TRANSIT_3?.remarks || "", doneAtTime: values?.status?.SHIPMENT_DEPARTED_TRANSIT_3?.doneAtTime || "",},
      SHIPMENT_ARRIVED_IN_DESTINATION: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_ARRIVED_IN_DESTINATION?.doneAt) || "", isDone: values?.status?.SHIPMENT_ARRIVED_IN_DESTINATION?.isDone??false, remarks: values?.status?.SHIPMENT_ARRIVED_IN_DESTINATION?.remarks || "", doneAtTime: values?.status?.SHIPMENT_ARRIVED_IN_DESTINATION?.doneAtTime || "",},
      SHIPMENT_IN_DESTINATION_WAREHOUSE: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_IN_DESTINATION_WAREHOUSE?.doneAt) || "", isDone: values?.status?.SHIPMENT_IN_DESTINATION_WAREHOUSE?.isDone??false, remarks: values?.status?.SHIPMENT_IN_DESTINATION_WAREHOUSE?.remarks || "", doneAtTime: values?.status?.SHIPMENT_IN_DESTINATION_WAREHOUSE?.doneAtTime || "",},
      SHIPMENT_OUT_FOR_DELIVERY: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_OUT_FOR_DELIVERY?.doneAt) || "", isDone: values?.status?.SHIPMENT_OUT_FOR_DELIVERY?.isDone??false, remarks: values?.status?.SHIPMENT_OUT_FOR_DELIVERY?.remarks || "", doneAtTime: values?.status?.SHIPMENT_OUT_FOR_DELIVERY?.doneAtTime || "",},
      SHIPMENT_DELIVERED: {doneAt: getDateTimeInNepalTimezone(values?.status?.SHIPMENT_DELIVERED?.doneAt) || "", isDone: values?.status?.SHIPMENT_DELIVERED?.isDone??false, remarks: values?.status?.SHIPMENT_DELIVERED?.remarks || "", doneAtTime: values?.status?.SHIPMENT_DELIVERED?.doneAtTime || "",},
    }
  }),
  validationSchema: yup.object().shape({
    status: yup.object().shape({
      SHIPMENT_BOOKED: eachStatusValidation,
      SHIPMENT_PICKED_UP: eachStatusValidation,
      SHIPMENT_IN_CUSTOM_PROCESS: eachStatusValidation,
      SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE: eachStatusValidation,
      SHIPMENT_DEPARTED: eachStatusValidation,
      SHIPMENT_IN_TRANSIT: eachStatusValidation,
      SHIPMENT_DEPARTED_TRANSIT_1: eachStatusValidation,
      SHIPMENT_IN_TRANSIT_2: eachStatusValidation,
      SHIPMENT_DEPARTED_TRANSIT_2: eachStatusValidation,
      SHIPMENT_IN_TRANSIT_3: eachStatusValidation,
      SHIPMENT_DEPARTED_TRANSIT_3: eachStatusValidation,
      SHIPMENT_ARRIVED_IN_DESTINATION: eachStatusValidation,
      SHIPMENT_IN_DESTINATION_WAREHOUSE: eachStatusValidation,
      SHIPMENT_OUT_FOR_DELIVERY: eachStatusValidation,
      SHIPMENT_DELIVERED: eachStatusValidation,
    }),
  }),
}

export const ShippingCountryList = [
  "AFGHANISTAN",
  "AUSTRALIA",
  "AUSTRIA",
  "BAHRAIN",
  "BANGLADESH",
  "BELGIUM",
  "BHUTAN",
  "BRAZIL",
  "BRUNEI",
  "BULGARIA",
  "CAMBODIA",
  "CANADA",
  "CHILE",
  "COLOMBIA",
  "CROATIA",
  "CYPRUS",
  "CZECH REPUBLIC",
  "DENMARK",
  "EGYPT",
  "FINLAND",
  "FRANCE",
  "GERMANY",
  "GREECE",
  "HONG KONG",
  "HUNGARY",
  "ICELAND",
  "INDONESIA",
  "IRAN",
  "IRAQ",
  "IRELAND",
  "ISRAEL",
  "ITALY",
  "JAPAN",
  "JORDAN",
  "KENYA",
  "KUWAIT",
  "LAOS",
  "LATVIA",
  "LEBANON",
  "LITHUANIA",
  "LUXEMBOURG",
  "MACAU",
  "MALAYSIA",
  "MALDIVES",
  "MALTA",
  "MAURITIUS",
  "MEXICO",
  "MYANMAR",
  "NEPAL",
  "NETHERLANDS",
  "NEW ZEALAND",
  "NORWAY",
  "OMAN",
  "PAKISTAN",
  "PHILIPPINES",
  "POLAND",
  "PORTUGAL",
  "QATAR",
  "ROMANIA",
  "SAUDI ARABIA",
  "SCOTLAND",
  "SINGAPORE",
  "SLOVAKIA",
  "SLOVENIA",
  "SOUTH AFRICA",
  "SOUTH KOREA",
  "SPAIN",
  "SRI LANKA",
  "SUDAN",
  "SWAZILAND",
  "SWEDEN",
  "SWITZERLAND",
  "SYRIA",
  "TAIWAN",
  "TANZANIA",
  "THAILAND",
  "TURKEY",
  "UAE",
  "UGANDA",
  "UK",
  "UKRAINE",
  "USA",
  "VIETNAM",
  "YEMEN",
];
