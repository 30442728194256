import ExcelJS from "exceljs";
import FileSaver from "file-saver";

async function downloadCSV(title: string = 'report', labels: string[], values: (string | number)[][]) {
    try {
        //    create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Report", {});
        worksheet.properties.defaultRowHeight = 30;
        worksheet.properties.defaultColWidth = 30;


        // add a table to a sheet
        worksheet.addTable({
            name: `${title} Report`,
            ref: "A1",
            headerRow: true,
            totalsRow: false,
            style: {
                showRowStripes: false,
            },
            // columns: data.map(each => ({name: each.label})),
            // rows: data.map(each => Object.values(each)),
            columns: labels.map(each => ({name: each})),
            rows: values,
        });
        //    save the workbook to a file
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().replace(/[-T:]/g, '_').slice(0, -5);
        const filename = `${title??"report"}_${formattedDate}.xlsx`;

        const bufferFile = await workbook.xlsx.writeBuffer();
        await FileSaver.saveAs(
            new Blob([bufferFile], {type: "application/octet-stream"}),
            filename
        );
    } catch (err) {
        throw err;
    }
}


export {downloadCSV}
