
export const getInputDateFormat = (
    date: string | Date,
    type: "date" | "datetime-local" = "date"
) => {
  if (date) {
    if (type == "date") {
      return new Date(date)?.toISOString()?.split("T")[0];
    } else {
      if (date?.toString().includes(".")) {
        return new Date(date)?.toISOString()?.split(".")[0];
      } else {
        return date;
      }
    }
  }
};

export const mapStatusWithLabel = (statusName: string): string => {
  switch (statusName) {
    case 'SHIPMENT_DELIVERED':
      return 'Shipment Delivered';
    case 'SHIPMENT_OUT_FOR_DELIVERY':
      return 'Shipment Out for Delivery';
    case 'SHIPMENT_IN_DESTINATION_WAREHOUSE':
      return 'Shipment in Destination Warehouse';
    case 'SHIPMENT_ARRIVED_IN_DESTINATION':
      return 'Shipment Arrived in Destination';
    case 'SHIPMENT_DEPARTED_TRANSIT_3':
      return 'Shipment Departed Transit 3';
    case 'SHIPMENT_IN_TRANSIT_3':
      return 'Shipment in Transit 3';
    case 'SHIPMENT_DEPARTED_TRANSIT_2':
      return 'Shipment Departed Transit 2';
    case 'SHIPMENT_IN_TRANSIT_2':
      return 'Shipment in Transit 2';
    case 'SHIPMENT_DEPARTED_TRANSIT_1':
      return 'Shipment Departed Transit 1';
    case 'SHIPMENT_IN_TRANSIT':
      return 'Shipment in Transit';
    case 'SHIPMENT_DEPARTED':
      return 'Shipment Departed';
    case 'SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE':
      return 'Shipment in Origin Custom Warehouse';
    case 'SHIPMENT_IN_CUSTOM_PROCESS':
      return 'Shipment in Custom Process';
    case 'SHIPMENT_PICKED_UP':
      return 'Shipment Picked Up';
    case 'SHIPMENT_BOOKED':
      return 'Shipment Booked';
      // Default case if no active status is found
    default:
      return 'No active status found';
  }
}
