const config = {
  development: {
    default:
      "http://localhost:5000/api/v1",
      // "https://api.hyre.com.np/api/v1",
  },
  production: {
    default: process.env.REACT_APP_API_HOST_URL,
  },
};

export default process.env.NODE_ENV === "development"
  ? config["development"]
  : config["production"];
