export enum QueryStringEnum {
  Archive = "archive",
  CURRENT_PAGE = "currentPage",
  TRACKING_NUMBER = "trackingNumber",
}
export enum ParamStringEnum {
  ID = "ID",
}
export enum AdminRoleEnum {
  ADMIN = "admin",
  TEACHER = "teacher",
}

export enum UserTypeEnum {
  STUDENT = "student",
  ADMIN = "admin",
  TEACHER = "teacher",
}

export enum IconTypeEnum {
  PASSWORD = "PASSWORD",
  SEARCH = "SEARCH",
  RIGHT_BOLD = "RIGHT_BOLD",
  UNSETTLED = "UNSETTLED",
  UN_ORDER_LIST = "UN_ORDER_LIST",
  ARCHIVE = "ARCHIVE",
  OPERATION_PROFIT = "OPERATION_PROFIT",
  PROPERTY_TOTAL = "PROPERTY_TOTAL",
  PDF = "PDF",
  FILE = "FILE",
  INVOICE = "INVOICE",
  IN_ACTIVE_INVOICE = "IN_ACTIVE_INVOICE",
  FINANCE = "FINANCE",
  IN_ACTIVE_FINANCE = "IN_ACTIVE_FINANCE",
  INVENTORY = "INVENTORY",
  IN_ACTIVE_INVENTORY = "IN_ACTIVE_INVENTORY",
  ATTACHMENT = "ATTACHMENT",
  ALERT = "ALERT",
  INFO = "INFO",
  RENTAL = "RENTAL",
  ADD = "ADD",
  KEY = "KEY",
  USERS = "USERS",
  DOLLAR = "DOLLAR",
  EYE_OPEN = "EYE_OPEN",
  EYE_CLOSE = "EYE_CLOSE",
  SELECTED_RADIO = "SELECTED_RADIO",
  UN_SELECTED_RADIO = "UN_SELECTED_RADIO",
  UP = "UP",
  MINUS = "MINUS",
  DOT = "DOT",
  MAIL = "MAIL",
  CHECK_ALL = "CHECK_ALL",
  DOWN_ARROW = "DOWN_ARROW",
  DOWNLOAD = "DOWNLOAD",
  DASHBOARD_SETTING = "DASHBOARD_SETTING",
  DASHBOARD_HAND_HOME = "DASHBOARD_HAND_HOME",
  UP_ARROW = "UP_ARROW",
  BOOKING_CANCELLED = "BOOKING_CANCELLED",
  LAYER = "LAYER",
  DASHBOARD = "DASHBOARD",
  BOOKING_CONFIRM = "BOOKING_CONFIRM",
  BOOKING_PENDING = "BOOKING_PENDING",
  SALES_SETTLED = "SALES_SETTLED",
  SALES_STATUS_DRAFT = "SALES_STATUS_DRAFT",
  UNIT_OCCUPIED = "UNIT_OCCUPIED",
  UNIT_VACANT = "UNIT_VACANT",
  HOME = "HOME",
  PROPERTY_VALUE = "PROPERTY_VALUE",
  RENTAL_INCOME_TOTAL = "RENTAL_INCOME_TOTAL",
  RENTAL_PROFIT = "RENTAL_PROFIT",
  RELOAD = "RELOAD",
  KEYS = "KEYS",
  GRAPH = "GRAPH",
  STAT_TAGS = "STAT_TAGS",
  SETTLED = "SETTLED",
  CANCEL = "CANCEL",
  UNIT = "UNIT",
  PROPERTY = "PROPERTY",
  DOWN = "DOWN",
  JOURNAL = "JOURNAL",
  Images = "Images",
  SETTING = "SETTING",
  EDIT = "EDIT",
  CLOSE = "CLOSE",
  BUILDING = "BUILDING",
  CALENDER = "CALENDER",
  NOTIFICATION = "NOTIFICATION",
  CLOCK = "CLOCK",
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  ADDRESS = "ADDRESS",
  DELETE = "DELETE",
  QUESTION = "QUESTION",
  SQUARE_FILL = "SQUARE_FILL",
  SQUARE_CHECK = "SQUARE_CHECK",
  TOGGLE_ON = "TOGGLE_ON",
  TOGGLE_OFF = "TOGGLE_OFF",
  CHECK = "CHECK",
  CHECK_FILL = "CHECK_FILL",
  UN_CHECK = "UN_CHECK",
  DISCOUNT = "DISCOUNT",
}

export enum AuthTokenENUM {
  accessToken = "accessToken",
  role = "role",
}
