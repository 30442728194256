import React from "react";
import { createContext, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {
  clearAuthToken,
  getAuthToken,
  setAuthToken,
  apiUrl,
  setRole,
  checkIsSystemRole,
} from "utils";
import { AuthTokenENUM } from "interfaces";
import { useNavigate } from "react-router-dom";
import { Loader } from "components";
import { PageLinks } from "routes";
import { Api } from "services";
import { useConfirmHook } from "hooks";

import { AppContextInterface } from "./interfaces";

export const AppContext = createContext<AppContextInterface>({
  state: {
    profileDetails: null,
    isActionSuccess: false,
    isError: false,
    message: "",
    isAuthenticated: false,
    isLoading: false,
    isAuthLoading: false,
  },
  handlers: {
    setAuthLoadingState(status: boolean) {},
    getCurrentProfileHandler() {},
    logoutHandler() {},
    setErrorState(flag: boolean, message?: string) {},
    setLoadingState(status: boolean) {},
    setSuccessState(flag: boolean, message?: string) {},
    loginHandler(token, isSystem: boolean) {},

  },
});

const AppProvider = ({ children }) => {
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const { confirm } = useConfirmHook();
  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthLoading, setAuthLoading] = useState(true);
  const [message, setMessage] = useState<string>("");
  const [profileDetails, setProfileDetails] = useState(null);
  const navigate = useNavigate();
  const { getApi } = Api();

  const logoutRedirectHandler = () => {
    if (checkIsSystemRole()) {
      navigate("/admin");
    } else {
      navigate(PageLinks.auth.login);
    }
  };

  const getCurrentProfileHandler = async () => {
    try {
      const isSystemToken = checkIsSystemRole();
      let res;
      if (isSystemToken) {
        res = await getApi(apiUrl?.auth?.get_currentUser);
      } else {
        res = await getApi(apiUrl?.student?.get_studentCurrentProfile);
      }
      setProfileDetails(res?.data);
      setAuthenticated(true);
    } catch (error) {
      setErrorState(true, error?.message);
    } finally {
    }
  };

  const authCheckHandler = async () => {
    try {
      setAuthLoading(true);
      if (getAuthToken()) {
        await getCurrentProfileHandler();
      } else {
        // logoutRedirectHandler();
        setAuthLoading(false);
      }
    } catch (err) {
      setErrorState(true, err?.message);
    } finally {
      setAuthLoading(false);
    }
  };
  useEffect(() => {
    (async () => {
      await authCheckHandler();
    })();
  }, []);

  // dispatch handlers
  const loginHandler = async (token: string, isSystem: boolean) => {
    setAuthToken(token);
    setRole(isSystem);
    await authCheckHandler();
  };

  useEffect(() => {
    if (isActionSuccess) {
      toast.success(message, {
        onClose: () => setSuccessState(false, ""),
      });
    }
    if (isError) {
      toast.error(message, {
        onClose: () => setErrorState(false, ""),
      });
    }
  }, [isError, isActionSuccess]);

  const logoutHandler = async () => {
    try {
      const isConfirm = await confirm("Are you sure?");
      if (!isConfirm) {
        return;
      }
      setLoading(true);
      clearAuthToken(AuthTokenENUM.accessToken);
      setAuthenticated(false);
      logoutRedirectHandler();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const setErrorState = (flag: boolean, message: string) => {
    setError(flag);
    setMessage(message);
  };
  const setLoadingState = (flag: boolean) => {
    setLoading(flag);
  };

  const setSuccessState = (flag: boolean, message: string) => {
    setActionSuccess(flag);
    setMessage(message);
  };
  const setAuthLoadingState = (flag: boolean) => {
    setAuthLoading(flag);
  };

  const contextValue = {
    state: {
      isLoading,
      isError,
      isActionSuccess,
      isAuthenticated,
      isAuthLoading,
      message,
      profileDetails,
    },
    handlers: {
      loginHandler,
      logoutHandler,
      setLoadingState,
      setAuthLoadingState,
      setSuccessState,
      setErrorState,
      getCurrentProfileHandler,
    },
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
      {isLoading && <Loader />}
      {isAuthLoading && <Loader />}
      <ToastContainer autoClose={1000} position={"bottom-right"} />
    </AppContext.Provider>
  );
};

export default AppProvider;
