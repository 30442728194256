import React, {useEffect, useState} from "react";
import PublicTopNavComponent from "../../../layout/component/PublicNavComponent";
import {useSearchParams} from "react-router-dom";
import {QueryStringEnum} from "../../../interfaces";
import {Form, Formik} from "formik";
import {apiUrl} from "../../../utils";
import {Api} from "../../../services";
import {Loader} from "../../../components";
import {OrderInterface, ShipmentStatus} from "../../../interfaces/module/quiz/order.interface";
import "./no_found_style.css"
import "./tracking_style.css"
import {AiFillCheckCircle, AiOutlineCheckCircle, AiOutlineInfoCircle} from "react-icons/ai";
import {mapStatusWithLabel} from "../../../helpers/HandlerHelpers";
import {mapStatus} from "../../studentModule/pages/ActiveOrder";

const TableLabel = ({children, label}) => {
    return <tr>
        <td className={"text-gray-500 text-sm"}>
            {label}
        </td>
        <td className="value text-sm font-black">
          <span>
            {children}
          </span>
        </td>
    </tr>
}

const RadioLabel = ({isChecked, label}) => {
    return <div
        className={
            "flex items-center gap-2 cursor-pointer select-none outline-tBlue"
        }
    >
        <div className={""}>{isChecked ? <AiFillCheckCircle className={" text-tGreen  text-[22px]"}/> :
            <AiOutlineCheckCircle className={"text-gray-300  text-[20px]"}/>}</div>
        <span className={"text-[16px]"}>{label}</span>
    </div>
}
const EachStepper = ({detail, name}: { detail: ShipmentStatus, name: string }) => {
    const isChecked = detail?.isDone ?? false;
    if (!isChecked) return <></>
    if (!isChecked && [
        "SHIPMENT_IN_TRANSIT",
        "SHIPMENT_DEPARTED_TRANSIT_1",
        "SHIPMENT_IN_TRANSIT_2",
        "SHIPMENT_DEPARTED_TRANSIT_2",
        "SHIPMENT_IN_TRANSIT_3",
        "SHIPMENT_DEPARTED_TRANSIT_3",
    ].includes(name)) {
        return <></>
    }
    const nepalTimeOptions: any = {
        timeZone: 'Asia/Kathmandu',
        hour12: true,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    };
    return <div>
        <RadioLabel label={mapStatusWithLabel(name)} isChecked={isChecked ?? false}/>
        {isChecked && <>
            {detail?.doneAt && <div className={"mt-1.5 flex flex-row items-center gap-1 ml-5 text-gray-400"}>
                <span
                    className={"text-[14px] font-semibold"}>{new Date(detail.doneAt).toDateString()}{"  "}{detail?.doneAtTime ?? ""}</span>
            </div>}
            {detail?.remarks && <div className={"ml-5 text-gray-400 text-[14px] whitespace-normal"}>"{detail?.remarks}"
            </div>}
        </>}
    </div>
}

const hidePhoneNumber = (phoneNumber: string): string => {
    if (phoneNumber.length < 5) return phoneNumber;
    // Extract the first two and last two digits
    const firstTwoDigits = phoneNumber.substring(0, 2);
    const lastTwoDigits = phoneNumber.substring(phoneNumber.length - 2);

    // Replace the middle digits with asterisks (*)
    const hiddenPart = '*'.repeat(phoneNumber.length - 4);

    // Concatenate the parts to form the hidden phone number
    return `${firstTwoDigits}${hiddenPart}${lastTwoDigits}`;
}

const hideEmail = (email: string): string => {
    if ((email ?? "").length < 5) return email ?? "";
    // Extract the first two and last two digits
    const firstTwoDigits = email.split('@');
    if (firstTwoDigits.length > 1) {
        const firstPart = firstTwoDigits[0];
        const firstTwo = firstPart.substring(0, 2);
        const firstLastTwo = firstPart.substring(firstPart.length - 2);
        const remainStart = '*'.repeat(firstPart.length - 4);
        return `${firstTwo}${remainStart}${firstLastTwo}@${firstTwoDigits[1]}`
    }
    return email;
}
const TrackingDetail = ({detail}: { detail: OrderInterface }) => {
    return <div className={'grid gap-10'}>
        <div className="grid lg:grid-cols-12  items-start justify-center gap-20 md:gap-5 md:grid-cols-1">
            <div className={"md:hidden sm:hidden xs:hidden "}></div>
            <span className={"lg:col-span-11  text-2xl font-extrabold text-gray-800 md:text-center"}>
          AWB: {(detail?.waybillNumber ?? '').toUpperCase()}
        </span>
        </div>

        <div className="grid lg:grid-cols-12 items-start  gap-20 md:gap-0 md:grid-cols-1">
            <div className={"md:hidden sm:hidden xs:hidden order-1"}></div>
            <div
                className={"grid lg:order-2 order-2 gap-5 col-span-2  lg:col-span-3 md:col-span-1 xs:mx-3 md:mx-5  lg:mt-10 px-[20px] xs:px-[5px]"}>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_BOOKED} name={'SHIPMENT_BOOKED'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_PICKED_UP}
                             name={'SHIPMENT_PICKED_UP'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_IN_CUSTOM_PROCESS}
                             name={'SHIPMENT_IN_CUSTOM_PROCESS'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE}
                             name={'SHIPMENT_IN_ORIGIN_CUSTOM_WAREHOUSE'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_DEPARTED} name={'SHIPMENT_DEPARTED'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_IN_TRANSIT}
                             name={'SHIPMENT_IN_TRANSIT'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_DEPARTED_TRANSIT_1}
                             name={'SHIPMENT_DEPARTED_TRANSIT_1'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_IN_TRANSIT_2}
                             name={'SHIPMENT_IN_TRANSIT_2'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_DEPARTED_TRANSIT_2}
                             name={'SHIPMENT_DEPARTED_TRANSIT_2'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_IN_TRANSIT_3}
                             name={'SHIPMENT_IN_TRANSIT_3'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_DEPARTED_TRANSIT_3}
                             name={'SHIPMENT_DEPARTED_TRANSIT_3'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_ARRIVED_IN_DESTINATION}
                             name={'SHIPMENT_ARRIVED_IN_DESTINATION'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_IN_DESTINATION_WAREHOUSE}
                             name={'SHIPMENT_IN_DESTINATION_WAREHOUSE'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_OUT_FOR_DELIVERY}
                             name={'SHIPMENT_OUT_FOR_DELIVERY'}/>
                <EachStepper detail={(detail as OrderInterface)?.status?.SHIPMENT_DELIVERED}
                             name={'SHIPMENT_DELIVERED'}/>
            </div>
            <div
                className={"grid lg:order-3 order-1  col-span-3 grid-cols-2 sm:grid-cols-1   lg:col-span-7 gap-5 border-l md:border-0 px-[20px] xs:px-[5px] md:px-[10px] xs:mx-3  md:mx-5 md:mt-[20px]  border-gray-300"}>
                <div className={""}>
                    <span className={"font-semibold font-black "}>Logistics Detail</span>
                    <table className="parcel-attributes mt-2">
                        <tbody>
                        <TableLabel label={"Tracking Number"}>{(detail?.waybillNumber ?? '').toUpperCase()}</TableLabel>
                        {detail?.expectedDeliveryDate && <TableLabel
                            label={"Expected Delivery Date"}>{(new Date(detail?.expectedDeliveryDate))?.toLocaleDateString()}</TableLabel>}
                        <TableLabel label={"Shipment Status"}>{mapStatus(detail?.status)}</TableLabel>
                        </tbody>
                    </table>
                </div>
                {detail?.package?.quantity &&
                    // detail?.package?.length && detail?.package?.breadth && detail?.package?.height &&
                    detail?.package?.weight &&
                    <div className={""}>
                        <span className={"font-semibold font-black"}>Package Detail</span>
                        <table className="parcel-attributes mt-2">
                            <tbody>
                            {detail?.package?.quantity && <TableLabel label={"Quantity"}>
                                {detail?.package?.quantity || ""}{" "}Pcs
                            </TableLabel>}
                            {(detail?.totalVolumetricWeight || detail?.package?.weight) && <TableLabel label={"V. Weight"}>
                                {detail?.totalVolumetricWeight === 0 ? detail?.package?.weight : detail?.totalVolumetricWeight}{" "} KG
                            </TableLabel>}
                            {/*{detail?.package?.length && detail?.package?.breadth && detail?.package?.height &&*/}
                            {/*    <TableLabel label={"Volume"}>{detail?.package?.length}cm x {detail?.package?.breadth}cm*/}
                            {/*        x {detail?.package?.height}cm</TableLabel>}*/}
                            {/*{detail?.package?.weight &&*/}
                            {/*    <TableLabel label={"Weight"}>{detail?.package?.weight} KG</TableLabel>}*/}
                            </tbody>
                        </table>
                    </div>}
                <div className={""}>
                    <span className={"font-semibold font-black"}>Shipper Detail</span>
                    <table className="parcel-attributes mt-2">
                        <tbody>
                        <TableLabel label={"Name"}>
                            {`${detail?.shipper?.firstName || ""} ${detail?.shipper?.lastName || ""}`}
                        </TableLabel>
                        {detail?.shipper?.address &&
                            <TableLabel label={"Address"}>{detail?.shipper?.address ?? ""}</TableLabel>}
                        {detail?.shipper?.phone &&
                            <TableLabel
                                label={"Phone Number"}>{hidePhoneNumber(detail?.shipper?.phone ?? "")}</TableLabel>}
                        {detail?.shipper?.email &&
                            <TableLabel label={"Email"}>{hideEmail(detail?.shipper?.email)}</TableLabel>}
                        </tbody>
                    </table>
                </div>
                <div className={""}>
                    <span className={"font-semibold font-black"}>Consignee Detail</span>
                    <table className="parcel-attributes mt-2">
                        <tbody>
                        <TableLabel label={"Name"}>
                            {`${detail?.consignee?.firstName || ""} ${detail?.consignee?.lastName || ""}`}
                        </TableLabel>
                        {detail?.consignee?.address &&
                            <TableLabel label={"Address"}>{detail?.consignee?.address ?? ""}</TableLabel>}
                        {detail?.consignee?.country &&
                            <TableLabel label={"Destination Country"}>{detail?.consignee?.country ?? ""}</TableLabel>}
                        {detail?.consignee?.phone && <TableLabel
                            label={"Phone Number"}>{hidePhoneNumber(detail?.consignee?.phone ?? "")}</TableLabel>}
                        {detail?.consignee?.email &&
                            <TableLabel label={"Email"}>{hideEmail(detail?.consignee?.email)}</TableLabel>}
                        </tbody>
                    </table>
                </div>

            </div>
            <div className={"md:hidden sm:hidden xs:hidden order-4"}></div>
        </div>
    </div>

}
const NoFoundOrder = ({trackingNumber}) => {
    return <div
        className="sm:px-4 md:px-8 flex flex-row gap-2 justify-center">
        <div className="flex flex-row justify-center items-center gap-10 py-10 px-20 sm:px-5 md:px-8  border  border-gray-400">
            <div className={"text-5xl text-red-500"}>
                <AiOutlineInfoCircle/>
            </div>
            <div
                className="flex flex-col">
                <h2 className="text-2xl md:text-xl sm:text-md  font-black pb-2" aria-hidden="true">
                    {(trackingNumber ?? "").toUpperCase()}
                </h2>
                <div className="text-xl sm:text-sm font-normal text-gray-400 pt-1">
                    Sorry, your tracking attempt was not successful. Please check your tracking number.
                </div>
            </div>
        </div>
    </div>
}

function TrackingPage() {
    const [query, setQuery] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState<OrderInterface>({} as OrderInterface);
    const [hasError, setError] = useState<boolean>(false);
    const [trackingNumber, setTrackingNumber] = useState(
        query.get(QueryStringEnum.TRACKING_NUMBER) ?? ""
    );
    const {getApi} = Api();


    const searchData = async (trackingNumber: string) => {
        try {
            setDetail({} as OrderInterface);
            setError(false);
            setLoading(true);
            if(trackingNumber) {
                let res = await getApi(apiUrl.student.track_logistics(trackingNumber));
                setDetail(res.data);
            }
        } catch (error) {
            setError(true);
            // Handle error if necessary
        } finally {
            setLoading(false);
        }
    };

    const handleSearch = (values) => {
        // Append the trackingNumber to the current URL
        searchData(values.trackingNumber); // Call the function when trackingNumber changes
        setTrackingNumber(values.trackingNumber);
    };

    useEffect(() => {
        query.set(QueryStringEnum.TRACKING_NUMBER, trackingNumber);
        setQuery(query);

    }, [trackingNumber]);

    return (
        <div className={"flex flex-col max-w-[100vw]"}>
            <PublicTopNavComponent/>
            <div className={"flex flex-col items-center my-5"}>
                <span className={"text-5xl sm:text-2xl font-extrabold text-gray-800"}>
                  Track Your Shipment
                </span>
            </div>
            <div className={"sm:px-2 bg-white py-10  my-5 flex flex-row justify-center items-center"}>
                <div>
                    <Formik
                        onSubmit={handleSearch}
                        initialValues={{trackingNumber: ""}}
                    >
                        {({values, handleChange, handleSubmit}) => (
                            <Form
                                className="w-[400px] sm:w-[80vw] flex flex-row items-center rounded-4px overflow-hidden bg-opacity-80 bg-white shadow-md ">
                                <input
                                    type="text"
                                    placeholder="Enter Tracking Number"
                                    className="border-none px-4 py-2 text-base outline-none tracking-wider w-full sm:max-w-[80vw]"
                                    name="trackingNumber"
                                    value={values.trackingNumber}
                                    onChange={handleChange}
                                />
                                <button
                                    type="submit"
                                    className="px-4 py-2 bg-blue-500 text-white cursor-pointer"
                                >
                                    Track
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
            {trackingNumber && loading && <Loader/>}
            {trackingNumber && !loading && !detail?.waybillNumber && <div>
                <NoFoundOrder trackingNumber={trackingNumber}/>
            </div>}
            {trackingNumber && detail?.waybillNumber && <div>
                <TrackingDetail detail={detail}/>
            </div>}
            <div className="mt-20 w-full">
                {/* Footer content with a minimum height of 500px */}
                <div className="p-8 min-h-500 flex flex-row items-center justify-center">
                    <div className="center">
                        {/* Powered by text and copyright */}
                        <p className="mt-4">Powered by IVTRD</p>
                        <p>&copy; {new Date().getFullYear()} Hyre. All rights reserved.</p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default TrackingPage;
