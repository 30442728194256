import React, { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext } from "context";
import { UserTypeEnum } from "interfaces";
import { PageLinks } from "../index";

export default function PublicRouteComponent() {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    if (state?.isAuthenticated) {
      if (state?.profileDetails?.role === UserTypeEnum.STUDENT) {
        navigate(PageLinks.quizSession.pendingQuiz);
      } else {
        navigate("/order");
      }
    }
  }, [state?.isAuthenticated]);

  return <Outlet />;
}
