import { useField } from "formik";
import "./styles.css";
import { MyRadioFieldInterface } from "./interfaces";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import {IconTypeEnum} from "../../interfaces";
import getIconHelper from "../../helpers/getIconHelpers";

export default function MyRadioInput({
  label,
  radioButtonType,
  defaultValue,
  ...props
}: MyRadioFieldInterface) {
  const [field, meta, helpers] = useField(props);
  const RadioCheckIcon = getIconHelper(IconTypeEnum.SELECTED_RADIO);
  const RadioUnCheckIcon = getIconHelper(IconTypeEnum.UN_SELECTED_RADIO);

  const RadioOnButton =
    radioButtonType === "radio" ? (
      <RadioCheckIcon className={"text-primary text-[20px] "} />
    ) : radioButtonType === "switch" ? (
      <BsToggleOn className={" text-tGreen  text-[30px]"} />
    ) : (
      <AiFillCheckCircle className={" text-tGreen  text-[20px]"} />
    );
  const RadioOffButton =
    radioButtonType === "radio" ? (
      <RadioUnCheckIcon className={"text-gray-500 text-[20px]"} />
    ) : radioButtonType === "switch" ? (
      <BsToggleOff className={"text-gray-600  text-[30px]"} />
    ) : (
      <AiOutlineCheckCircle className={"text-gray-300  text-[20px]"} />
    );
  const onClickHandler = () => {
    if (props?.disabled) {
      return;
    }
    if (defaultValue) {
      helpers.setValue(defaultValue);
    } else {
      helpers.setValue(!field?.value);
    }
  };
  const isSelected = () => {
    let status;
    if (typeof defaultValue === "boolean" || defaultValue) {
      status = defaultValue === field?.value;
    } else {
      status = field?.value;
    }
    return status;
  };

  return (
    <button
      type={"button"}
      className={
        "flex items-center gap-2 cursor-pointer select-none outline-tBlue"
      }
      onClick={onClickHandler}
    >
      <div className={""}>{isSelected() ? RadioOnButton : RadioOffButton}</div>
      <span className={"text-[14px]"}>{label}</span>
    </button>
  );
}
