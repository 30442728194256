import React, { useContext } from "react";
import { PageLinks } from "routes";
import { NavLink, useNavigate } from "react-router-dom";
import { MyButton } from "components";
import { AppContext } from "context";
import { useAuthorizationHook } from "hooks";
import { UserTypeEnum } from "interfaces";

function TopNavComponent() {
  const {
    state: { profileDetails },
    handlers: { logoutHandler },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { checkCanAccessHandler } = useAuthorizationHook();
  const accessByAdmin = checkCanAccessHandler([UserTypeEnum.ADMIN]);

  const links = [
    accessByAdmin && {
      name: "Users",
      path: PageLinks.user.view,
    },
    accessByAdmin && {
      name: "Active Orders",
      path: PageLinks.student.view,
    },
    accessByAdmin && {
      name: "Order History",
      path: PageLinks.student.history,
    },
  ];
  return (
    <div
      className={
        "flex px-10 sm:px-2 bg-white bg-white items-center justify-between gap-5"
      }
    >
      <div
        onClick={() => navigate("/home")}
        className={
          "text-[18px] sm:text-[16px] font-displayBold cursor-pointer uppercase text-primary"
        }
      >
        <b>Hyre - Track & Trace</b>
      </div>
      <div className={"flex items-center gap-5 sm:gap-2"}>
        {links?.map((e, key) => {
          if (!e) return undefined;
          return (
            <NavLink
              key={key}
              className={({ isActive }) =>
                `${
                  isActive ? "text-primary" : "text-gray-400"
                } text-[14px] sm:text-[14px] font-semibold  hover:text-gray-600`
              }
              to={e?.path}
            >
              {e?.name}
            </NavLink>
          );
        })}

        <MyButton
          size={"md"}
          isOutline
          name={"Logout"}
          onClick={logoutHandler}
        />
      </div>
    </div>
  );
}

export default TopNavComponent;
