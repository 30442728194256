import React from "react";
import { Route, Navigate } from "react-router-dom";
import { LoginPage } from "./pages";
import { DataContextProvider } from "./context";
import TrackingPage from "./pages/TrackingPage";

function Index() {
  return (
    <Route path="/" element={<DataContextProvider />}>
      <Route path={"/"} index element={<Navigate to={"/tracking"} />} />
      <Route path={"/tracking"} index element={<TrackingPage />} />
      <Route path={"/admin"} element={<LoginPage isAdmin={true} />} />
    </Route>
  );
}

export default Index;
