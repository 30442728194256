import React from "react";
import {Route} from "react-router-dom";
import {EditStudentPage, StudentPage} from "./pages";
import {ParamStringEnum, UserTypeEnum} from "interfaces";
import {AccessVerifier} from "templates";
import ChangeStatusModal from "./pages/ChangeStatusModal";

function Index() {
    return (
        <Route path="/order">
            <Route
                path={""}
                element={
                    <StudentPage/>
                }
            />

            <Route
                path={"create"}
                element={
                    <EditStudentPage/>
                }
            />
            <Route
                path={`edit/:${ParamStringEnum.ID}`}
                element={
                    <AccessVerifier role={[UserTypeEnum.ADMIN, UserTypeEnum.TEACHER]}>
                        <EditStudentPage/>
                    </AccessVerifier>
                }
            />
            <Route
                path={`modal/status/edit/:${ParamStringEnum.ID}`}
                element={
                    <ChangeStatusModal/>
                }
            />
        </Route>
    );
}

export default Index;
