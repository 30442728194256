import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { PageTemplate } from "templates";
import { AdminContext, AppContext } from "context";
import { Form, Formik } from "formik";
import { UserFormik } from "../helpers";
import {
  FormSubmitButtonsComponent,
  MySelectField,
  MyTextField,
} from "components";
import { AdminRoleEnum, ParamStringEnum } from "interfaces";
import { PageLinks } from "routes";

function EditUserPage() {
  const navigate = useNavigate();
  const params = useParams<ParamStringEnum>();
  const editID = params.ID;
  const {
    handlers: { setLoadingState },
  } = useContext(AppContext);
  const { editDetailsHandler, getDetailsHandler, details } =
    useContext(AdminContext);
  useEffect(() => {
    (async () => {
      if (editID) {
        setLoadingState(true);
        await getDetailsHandler(editID);
        setLoadingState(false);
      }
    })();
  }, [editID]);

  return (
    <PageTemplate title={editID ? "Edit User" : "Add User"}>
      <Formik
        validationSchema={UserFormik.validationSchema}
        enableReinitialize
        initialValues={UserFormik.initialValues(editID && details)}
        onSubmit={(values) =>
          editDetailsHandler(values, null, {
            onSuccess(payload?: any): any {
              navigate(PageLinks.user.view);
            },
          })
        }
      >
        <Form className={"flex flex-col gap-20"}>
          <div className={"grid gap-5 items-start grid-cols-3"}>
            <MyTextField
              name={"firstName"}
              isRequired
              label={"First Name"}
              placeholder={"eg: John"}
            />
            <MyTextField
              name={"middleName"}
              label={"Middle Name"}
              placeholder={"eg: Kumar"}
            />
            <MyTextField
              isRequired
              name={"lastName"}
              label={"Last Name"}
              placeholder={"eg: Doe"}
            />
            <MyTextField
              name={"email"}
              isRequired
              label={"Email"}
              placeholder={"eg: johndoe@gmail.com"}
            />
            <>
              <MyTextField
                name={"password"}
                label={"Password"}
                isRequired
                placeholder={"Enter password"}
              />
              <MySelectField
                name={"role"}
                label={"Role"}
                option={{
                  selectOptions: [
                    {
                      label: "Admin",
                      value: AdminRoleEnum.ADMIN,
                    },
                  ],
                }}
              />
            </>
          </div>
          <FormSubmitButtonsComponent haveSubmitButton haveCancelButton />
        </Form>
      </Formik>
    </PageTemplate>
  );
}

export default EditUserPage;
